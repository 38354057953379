/* import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

// Use environment variables for Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0]; // if already initialized, use that one
}

// Initialize Firestore
const db = getFirestore(app);

// Initialize Functions
const functions = getFunctions(app);
const addUserRole = httpsCallable(functions, 'addUserRole');

// Export the Firestore instance
export { db, functions, addUserRole };
 */
import { initializeApp, getApps } from "firebase/app";
import {
  getFirestore,
  setLogLevel,
  getDocs,
  collection,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth"; // Import Firebase Auth
// import { set } from "lodash";

// Use environment variables for Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

// Initialize Firestore
console.log("[Firebase] initializing firebase with config", firebaseConfig);
const db = getFirestore(app);

// Get a document and value
// const doc = getDoc(docRef);

console.log("[Firebase] initialized, test query");
// const querySnapshot = await getDocs(collection(db, "users"));
// querySnapshot.forEach((doc) => {
//   console.log(`[Firebase] Document check: ${doc.id} => ${doc.data()}`);
// });

// setLogLevel("debug");

// Initialize Functions
const functions = getFunctions(app);
const addUserRole = httpsCallable(functions, "addUserRole");

// Initialize Auth
const auth = getAuth(app); // Initialize Firebase Authentication

// Export the Firestore instance, Functions, and Auth
export { db, functions, addUserRole, auth }; // Include `auth` in the export
