import React, { useEffect, useState, useRef } from "react";
import { useFirebase } from "contexts/FirebaseContext";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Link, Box } from "@mui/material";
import MainCard from "components/MainCard";
import ManagerCompaniesList from "components/ManagerCompaniesList";
import SearchFormEntries from "components/SearchFormEntries";
import OffRatioEvents from "sections/dashboard/analytics/OffRatioEvents";
import JumpToSection from "components/JumpToSection";

// Initialize Firestore
const db = getFirestore();

const DashboardManager = () => {
  const { user } = useFirebase();
  const theme = useTheme();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  const eventsRef = useRef(null);
  const userListRef = useRef(null);

  useEffect(() => {
    const fetchCompany = async () => {
      if (user && user.uid) {
        console.log("Fetching company for user:", user.uid);

        // Fetch user data to get the company reference
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        console.log("Fetched User Data:", userData);

        if (userData && userData.role === "manager" && userData.companyRef) {
          console.log("User is a manager, fetching company using companyRef");

          // Fetch the company data using the company reference
          const companyDoc = await getDoc(userData.companyRef);

          if (companyDoc.exists()) {
            const companyData = companyDoc.data();
            console.log("Fetched company:", companyData);
            setCompany({ id: companyDoc.id, ...companyData });
          } else {
            console.log("No company found for the manager.");
          }
        } else {
          console.log("User is not a manager or company reference not found.");
        }

        setLoading(false);
      }
    };

    fetchCompany();
  }, [user]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const handleScrollToEvents = (event) => {
    event.preventDefault();
    eventsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToUserList = (event) => {
    event.preventDefault();
    userListRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const sections = [
    { name: "Events", onClick: handleScrollToEvents },
    { name: "User List", onClick: handleScrollToUserList },
  ];

  return (
    <>
      <JumpToSection sections={sections} />

      <Grid item xs={12} sx={{ marginBottom: "4rem" }}>
        {company && company.companyName ? (
          <SearchFormEntries companyName={company.companyName} />
        ) : (
          <Typography>No company found</Typography>
        )}
      </Grid>

      <Grid container rowSpacing={4.5} columnSpacing={3}>
        {/* row 1 */}
        <Grid item xs={12} ref={eventsRef}>
          <Typography variant="h5">Off-Ratio Events</Typography>
          <MainCard
            content={false}
            sx={{
              mt: 0,
              p: 0,
              boxShadow: "none",
              border: "none",
              bgcolor: "transparent",
            }}
          >
            {company ? (
              <OffRatioEvents companyName={company.companyName} />
            ) : (
              <Typography>No company found</Typography>
            )}
          </MainCard>
        </Grid>

        {/* row 2 */}
        <Grid item xs={12} ref={userListRef}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">User List</Typography>
            </Grid>
          </Grid>
          <MainCard content={false} sx={{ mt: 1.5 }}>
            <Grid item>
              <Grid container>
                <Grid item xs={12}>
                  <ManagerCompaniesList />
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardManager;
