// // NavUser.js
// import React, { useContext } from "react";
// import { Box, List, ListItem, ListItemText } from "@mui/material";
// import { useFirebase } from "../contexts/FirebaseContext";
// import useFetchUserData from "../hooks/useFetchUserData";

// const NavUser = () => {
//   // Use FirebaseContext to get the authenticated user
//   const { user, userData, userDataLoading: loading } = useFirebase();
//   // const { userData, loading } = useFetchUserData(user?.uid); // Use `id` from your context's user object

//   const secondaryText = loading
//     ? "Loading ..."
//     : `${userData?.company || "No Company"} - ${userData?.role || "No Role"}`;

//   // Render user profile details
//   return (
//     <Box sx={{ p: 1.25 }}>
//       <List disablePadding>
//         <ListItem disablePadding>
//           <ListItemText primary={user?.email} secondary={secondaryText} />
//         </ListItem>
//       </List>
//     </Box>
//   );
// };

// export default NavUser;
// NavUser.js
import React, { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { useFirebase } from "../contexts/FirebaseContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const NavUser = () => {
  const { user, userData, userDataLoading: loading } = useFirebase();
  const [companyName, setCompanyName] = useState("Loading...");

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (userData?.companyRef) {
        const companyDoc = await getDoc(userData.companyRef);
        if (companyDoc.exists()) {
          setCompanyName(companyDoc.data().companyName);
        } else {
          setCompanyName("No Company");
        }
      } else {
        setCompanyName("No Company");
      }
    };

    fetchCompanyData();
  }, [userData]);

  const secondaryText = loading
    ? "Loading ..."
    : `${companyName} - ${userData?.role || "No Role"}`;

  return (
    <Box sx={{ p: 1.25 }}>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemText primary={user?.email} secondary={secondaryText} />
        </ListItem>
      </List>
    </Box>
  );
};

export default NavUser;
