// In src/pages/dashboard/unassigned.js
import React from 'react';

const UnassignedDashboard = () => {
  return (
    <div>
      <h1>Account Activation Pending</h1>
      <p>Thank you for signing up. Your account is currently pending activation by your technical team or manager.</p>
    </div>
  );
};

export default UnassignedDashboard;
