import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Link, Stack, Typography } from "@mui/material";
import ScrollToTopButton from "components/ScrollToTopButton";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: "24px 16px 0px", mt: "auto" }}
    >
      <Typography variant="caption">
        &copy; {currentYear} FSI - All rights reserved
      </Typography>
      <Stack
        spacing={1.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link
          component={RouterLink}
          to="https://fsi.co/about"
          target="_blank"
          variant="caption"
          color="textPrimary"
        >
          About us
        </Link>
        <Link
          component={RouterLink}
          to="https://fsi.co/privacy-policy/"
          target="_blank"
          variant="caption"
          color="textPrimary"
        >
          Privacy
        </Link>
        <Link
          component={RouterLink}
          to="https://fsi.co/terms-and-conditions/"
          target="_blank"
          variant="caption"
          color="textPrimary"
        >
          Terms
        </Link>
        <ScrollToTopButton />
      </Stack>
    </Stack>
  );
};

export default Footer;
