import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useContext,
  useCallback,
  useReducer,
} from "react"; // Add any other hooks or utilities needed
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import {
  Chip,
  Box,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  TextField,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  doc,
  updateDoc,
  getDocs,
  deleteField,
} from "firebase/firestore";
import { populateUserRefs, usersListQuery } from "../queries/usersList"; // Make sure this query is correctly configured to fetch users by companyRef
import { getRoleChipStyle } from "../utils/styleUtils";
import { alpha, useTheme } from "@mui/material/styles";
import {
  EditOutlined,
  SendOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import * as R from "ramda";
import { useFirebase } from "../contexts/FirebaseContext";
import { produce } from "immer";
import { PhoneInput } from "./PhoneInput";
import { useField } from "formik";
import { systemQuery, systemQueryOptions } from "queries/system";
import "../assets/css/styles.css";
const prune = R.reject(R.equals(undefined));

const useEditableCell = (props) => {
  const { getValue, row, column, table } = props;
  const initialValue = getValue();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    table.options.meta?.updateData({ row, column, value });
  };

  const setRowData = ({ key, value }) => {
    table.options.meta?.updateData({ row, column: { id: key }, value });
  };

  const rowData = table.options.meta?.editableData[row.index];

  useEffect(() => {
    if (rowData && rowData[column.id] && rowData[column.id] !== value) {
      setValue((prevValue) => {
        // Check if the previous value is still different to avoid unnecessary updates
        return prevValue !== rowData[column.id]
          ? rowData[column.id]
          : prevValue;
      });
    }
  }, [rowData, column.id, value]);

  return { value, setValue, onBlur, rowData, setRowData };
};

function DefaultColumnEditCellHelperComponent(props) {
  return <>{props.column.columnDef.editCell(props)}</>;
}

// Give our default column cell renderer editing superpowers!
const defaultColumn = {
  cell: (props) => {
    const { getValue, row, column, table } = props;
    return !!table.options.meta.editingRowStatus[row.index] ? (
      <DefaultColumnEditCellHelperComponent {...props} />
    ) : (
      column.columnDef.displayCell(props)
    );
  },
  editCell: function EditCell(props) {
    const { value, setValue, onBlur } = useEditableCell(props);
    return (
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        disabled={
          props.table.options.meta.editingRowStatus[props.row.index] ===
          "LOADING"
        }
        size="small"
      />
    );
  },
  displayCell: (info) => info.renderValue(),
};

// const columns = [
const createColumns = (companyRef) => [
  {
    accessorKey: "name",
    header: "Name",
    displayCell: ({ row }) => {
      return <span className="bold-name">{row.original.name}</span>;
    },
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ getValue }) =>
      getValue() ? (
        <span>
          <a
            href={`mailto:${getValue()}`}
            className="text"
            style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
          >
            {getValue()}
          </a>
          <a href={`mailto:${getValue()}`} className="icon">
            <IconButton color="primary" aria-label="email">
              <MailOutlined />{" "}
            </IconButton>
          </a>
        </span>
      ) : (
        ""
      ),
  },
  {
    accessorKey: "phone",
    header: "Phone",
    displayCell: (props) =>
      props.getValue() ? (
        <span>
          <a
            href={"tel:" + props.getValue()}
            className="text"
            style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
          >
            {props.getValue()}
          </a>
          <a href={"tel:" + props.getValue()} className="icon">
            <IconButton color="primary" aria-label="call">
              <PhoneOutlined />
            </IconButton>
          </a>
        </span>
      ) : (
        ""
      ),
    editCell: function EditCell(info) {
      const { value, onBlur, setValue } = useEditableCell(info);
      return (
        <PhoneInput
          value={value}
          onChange={(v, e) => setValue(v)}
          onBlur={onBlur}
          size="small"
        />
      );
    },
  },
  {
    accessorKey: "role",
    header: "Role",
    headerClassName: "hide-on-mobile",
    className: "hide-on-mobile",
    displayCell: function RoleCell({ getValue }) {
      const theme = useTheme();
      return (
        <Chip
          style={getRoleChipStyle(getValue(), theme)}
          label={getValue()}
          variant="outlined"
          size="small"
        />
      );
    },
    editCell: function EditCell(info) {
      const { row } = info;
      const { value, onBlur, setValue, setRowData } = useEditableCell(info);
      if (!row) return null;
      return (
        <Select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            if (e.target.value === "unassigned") {
              setRowData({ key: "companyRef", value: deleteField() });
            } else {
              setRowData({
                key: "companyRef",
                value: row?.original.companyRef,
              });
            }
          }}
          onBlur={onBlur}
          size="small"
          fullWidth
        >
          {["tech", "manager", "foamer", "unassigned"].map((role) => (
            <MenuItem value={role} key={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    id: "tech",
    header: "Tech",
    headerClassName: "hide-on-mobile",
    className: "hide-on-mobile",
    accessorFn: (row) => row.tech?.name || "Not Assigned",
    editCell: function EditCell(info) {
      const { user: currentUser } = useFirebase();
      const { value, onBlur, setValue, setRowData } = useEditableCell(info);
      if (value === "Not Assigned") {
        return (
          <>
            <IconButton
              onClick={() => {
                setValue(currentUser.name);
                setRowData({
                  key: "techRef",
                  value: doc(db, "users", currentUser.uid),
                });
              }}
              title="Associate with tech"
              aria-label="Associate with tech"
            >
              <PlusCircleOutlined />
            </IconButton>{" "}
            {value}
          </>
        );
      }
      return (
        <>
          <IconButton
            onClick={() => {
              setValue("Not Assigned");
              setRowData({ key: "techRef", value: deleteField() });
            }}
            title="Dissociate with tech"
            aria-label="Dissociate with tech"
          >
            <MinusCircleOutlined />
          </IconButton>{" "}
          {value}
        </>
      );
    },
  },
  {
    id: "manager",
    header: "Manager",
    headerClassName: "hide-on-mobile",
    className: "hide-on-mobile",
    accessorFn: (row) => row.managerRef?.id || ``,
    displayCell: (info) => info.row.original.manager?.name,
    editCell: function EditCell(info) {
      const { row, table } = info;
      // console.log("Row data on EditCell call:", row);

      const { value, setValue, setRowData } = useEditableCell(info); // Ensure the hook call is at the top

      const { user: currentUser } = useFirebase(); // Ensure currentUser is defined
      const companyRef = row.original?.companyRef; // Safely access companyRef

      // Always call the useQuery hook unconditionally
      const managerQuery = useQuery({
        ...usersListQuery(currentUser),
        select: (data) =>
          data?.filter(
            (user) =>
              user.role === "manager" && user.companyRef?.id === companyRef?.id
          ),
        enabled: !!companyRef, // Ensure the query only runs if companyRef is defined
      });

      // console.log("companyRef:", companyRef);

      const managers = managerQuery.data;
      // console.log("Managers fetched:", managers);
      const { isLoading, error } = managerQuery;

      if (!row.original) {
        // console.error("row.original is undefined:", row);
        return <Typography>Error: row data is undefined.</Typography>;
      }

      if (!companyRef) {
        // console.error("companyRef is undefined for row:", row);
        return <Typography>Error: companyRef is undefined.</Typography>;
      }

      if (isLoading) return <CircularProgress size={24} />;
      if (error)
        return <Typography>Error loading managers: {error.message}</Typography>;
      if (!managers || managers.length === 0)
        return <Typography>No managers found</Typography>;

      return (
        <Select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setRowData({
              key: "managerRef",
              value: e.target.value
                ? doc(db, "users", e.target.value)
                : deleteField(),
            });
          }}
          inputProps={{ "aria-label": "Select manager" }}
          fullWidth
        >
          <MenuItem value={null}>
            <em>None</em>
          </MenuItem>
          {managers.map((manager) => (
            <MenuItem key={manager.id} value={manager.id}>
              {manager.name}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    id: "slugGun",
    header: "System",
    accessorFn: (row) => row.slugGunRef?.id,
    displayCell: (info) =>
      `${info.row.original.slugGun?.machineName} - ${info.row.original.slugGun?.system}`,
    editCell: function EditCell(info) {
      const { value, setValue, onBlur } = useEditableCell(info);

      const {
        data: systems,
        isLoading,
        error,
      } = useQuery(systemQueryOptions(info.row.original.companyRef));

      if (isLoading) return <CircularProgress size={24} />;
      if (error)
        return <Typography>Error loading systems: {error.message}</Typography>;
      if (!systems || systems.length === 0)
        return <Typography>No systems found</Typography>;

      const handleChange = async (e) => {
        const newValue = e.target.value;
        setValue(newValue);

        const selectedSystem = systems.find(
          (machine) => machine.id === newValue
        );
        if (selectedSystem) {
          const newSlugGunRef = doc(db, "machines", selectedSystem.id);

          // console.log("Updating row:", info.row.index);
          // console.log("New slugGunRef:", newSlugGunRef);

          // Update the table's meta data
          info.table.options.meta?.updateData({
            row: info.row,
            column: { id: "slugGunRef" },
            value: newSlugGunRef,
          });

          try {
            // Update Firestore document
            await updateDoc(doc(db, "users", info.row.original.id), {
              slugGunRef: newSlugGunRef,
            });
            // console.log("Document updated successfully");
          } catch (error) {
            // console.error("Error updating document:", error);
          }
        }
      };

      return (
        <Select value={value} onChange={handleChange} size="small" fullWidth>
          {systems.map((machine) => (
            <MenuItem value={machine.id} key={machine.id}>
              {machine.machineName} - {machine.system}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    id: "actions",
    header: "Actions",
    displayCell: ({ row, table }) => (
      <>
        <IconButton
          onClick={() =>
            table.options.meta.setEditingRowStatus({ [row.index]: "EDITING" })
          }
        >
          <EditOutlined />
        </IconButton>
      </>
    ),
    editCell: function EditCell(info) {
      const { row, column, table } = info;
      const { value, onBlur, setValue, rowData, setRowData } =
        useEditableCell(info);
      const originalCompany = row.original.companyRef;
      const willDissociate =
        rowData?.companyRef?.["_methodName"] !== "deleteField";
      return (
        <>
          <IconButton
            onClick={() => {
              table.options.meta?.updateData({
                row,
                column: { id: "companyRef" },
                value: willDissociate ? deleteField() : originalCompany,
              });
              if (willDissociate) {
                setRowData({ key: "role", value: "unassigned" });
              } else {
                setRowData({ key: "role", value: row.original.role });
              }
            }}
            title={
              willDissociate
                ? "Dissociate with company"
                : "Associate with company"
            }
            aria-label={
              willDissociate
                ? "Dissociate with company"
                : "Associate with company"
            }
          >
            {willDissociate ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
          </IconButton>
          {table.options.meta.editingRowStatus[row.index] === "LOADING" ? (
            <CircularProgress size="2em" />
          ) : (
            <IconButton onClick={() => table.options.meta.saveData({ row })}>
              <SendOutlined />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              table.options.meta.cancelEdit({ row });
            }}
          >
            <CloseOutlined />
          </IconButton>
        </>
      );
    },
  },
];

const fetchManagers = async (companyRef) => {
  // console.log("Fetching managers for companyRef:", companyRef);
  const usersCollection = collection(db, "users");

  if (!companyRef) {
    // console.error("Invalid companyRef:", companyRef);
    return []; // Return empty if no valid companyRef provided
  }

  try {
    const q = query(
      usersCollection,
      where("companyRef", "==", companyRef),
      where("role", "==", "manager")
    );
    const querySnapshot = await getDocs(q);
    const managers = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // console.log("Fetched Managers:", managers);
    return managers;
  } catch (error) {
    // console.error("Error fetching managers:", error);
    return []; // Return empty array on error
  }
};

const CompanyUsersList = ({ companyRef, companyName }) => {
  const theme = useTheme();
  const { user: currentUser } = useFirebase();

  const [editUserId, setEditUserId] = useState(null);

  const [editFormData, dispatch] = useReducer((prev, action) => {
    switch (action.type) {
      case "FIELD_CHANGE":
        return { ...prev, [action.key]: action.value };
      case "REMOVE_COMPANY":
        return { ...prev, companyRef: null };
      case "REMOVE_TECH":
        return { ...prev, techRef: null };
      case "INIT":
        return { ...action.payload };
      case "TERMINATE":
        return {};
      default:
        return prev;
    }
  }, {});

  const handleEditClick = (user) => {
    setEditUserId(user.id);
    dispatch({
      type: "INIT",
      payload: {
        name: user.name,
        email: user.email,
        phone: user.phone,
        role: user.role,
        tech: user.tech?.name,
        manager: user.manager?.name,
        companyRef: user.companyRef,
      },
    });
  };

  const handleSaveEdit = async (userId) => {
    try {
      const userRef = doc(db, "users", userId);
      // console.log("Updating user with data:", prune(editFormData));
      await updateDoc(userRef, prune(editFormData));
      alert("User updated successfully!");
      dispatch({ type: "TERMINATE" });
    } catch (error) {
      // console.error("Failed to update user:", error);
      alert("Failed to update user.");
    }
  };

  const handleCancelEdit = () => {
    dispatch({ type: "TERMINATE" });
  };

  const [editingRowStatus, setEditingRowStatus] = useReducer(
    (state, editingRows) => {
      // console.log(state, editingRows, { ...state, ...editingRows });
      return Object.assign({}, state, editingRows);
    },
    {}
  );

  const queryClient = useQueryClient();

  const fetchUsers = async () => {
    const usersCollection = collection(db, "users");
    const q = query(usersCollection, where("companyRef", "==", companyRef));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  // console.log("Companyref", companyRef.id);

  const usersQuery = useQuery({
    ...usersListQuery(currentUser),
    enabled: !!companyRef,
    select: (data) =>
      data.filter((user) => user.companyRef?.id === companyRef?.id),
  });

  const [tableData, setTableData] = useState({});

  const tableInstance = useReactTable({
    data: usersQuery.data || [],
    // columns,
    columns: useMemo(() => createColumns(companyRef), [companyRef]),
    getCoreRowModel: getCoreRowModel(),
    defaultColumn,
    meta: {
      editableData: tableData,
      updateData: ({ row, column, value }) => {
        if (!row || typeof row.index !== "number") {
          // console.error("Invalid row or row index", { row });
          return; // Prevent proceeding if row or row.index is invalid
        }

        // console.log("updating data", { row, column, value });

        setTableData(
          produce((draft) => {
            draft[row.index] ??= {};
            draft[row.index][column.id] = value;
          })
        ); // Update state to trigger re-render
      },

      saveData: ({ row, column }) => {
        if (!tableData[row.index]) {
          // console.error("No data to save for row", row.index);
          setEditingRowStatus({ [row.index]: false });
          return;
        }

        // You must update this for every field you want to save
        const dataToSave = R.pick(
          [
            "name",
            "email",
            "phone",
            "role",
            "companyRef",
            "techRef",
            "managerRef",
            "slugGunRef",
          ],
          prune(tableData[row.index])
        );

        const dataToPreview = R.pick(
          ["company", "tech", "manager", "slugGun"],
          prune(tableData[row.index])
        );

        // console.log("Saving data for row", row.index, tableData[row.index]);

        updateDoc(doc(db, "users", row.original.id), dataToSave)
          .then(() => {
            // console.log("Data saved successfully for row", row.index);
          })
          .catch((error) => {
            // console.error("Failed to save data for row", row.index, error);
          })
          .finally(() => {
            setEditingRowStatus({ [row.index]: false }); // Reset editing status
            setTableData((prev) => R.dissoc(row.index, prev)); // Remove the row's temporary data
            const newUserRaw = { ...row.original, ...dataToSave };

            function updateQueryData(prev, newUser) {
              const index = prev.findIndex(
                (user) => user.id === row.original.id
              );
              const updated = produce(prev, (draft) => {
                if (index !== -1) {
                  draft[index] = newUser;
                }
              });
              // console.log("Updated users list: ", updated);
              return updated;
            }

            // First, update with the preview data from the editor
            // console.log("Extra special new data", {
            //   ...newUserRaw,
            //   ...dataToPreview,
            // });
            queryClient.setQueryData(["usersList"], (prev) =>
              updateQueryData(prev, { ...newUserRaw, ...dataToPreview })
            );

            // Then invalidate queries
            queryClient.invalidateQueries(["usersList"]);

            // Then, use firebase functions to get the true state of the data
            populateUserRefs(newUserRaw).then((newUser) =>
              queryClient.setQueryData(["usersList"], (prev) =>
                updateQueryData(prev, newUser)
              )
            );
          });
      },
      cancelEdit: ({ row }) => {
        setEditingRowStatus({ [row.index]: false });
        setTableData((prev) => R.dissoc(row.index, prev));
      },
      editingRowStatus: editingRowStatus,
      setEditingRowStatus: setEditingRowStatus,
    },
  });

  if (usersQuery.isLoading) return <Typography>Loading users...</Typography>;
  if (usersQuery.error) return <Typography>Error loading users.</Typography>;

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead className="hide-on-mobile">
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isHideOnMobile =
                    header.id === "tech" ||
                    header.id === "manager" ||
                    header.id === "slugGun";
                  return (
                    <TableCell
                      key={header.id}
                      className={
                        isHideOnMobile ? "hide-on-mobile" : "default-class"
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {tableInstance.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const isHideOnMobile =
                    cell.column.id === "tech" ||
                    cell.column.id === "manager" ||
                    cell.column.id === "slugGun";
                  return (
                    <TableCell
                      key={cell.id}
                      className={
                        isHideOnMobile ? "hide-on-mobile" : "default-class"
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CompanyUsersList;
