import React, { useEffect, useState, useMemo } from "react";
import { useFirebase } from "../contexts/FirebaseContext"; // Adjust the import path as needed
// import { rolePermissions } from "../config/roles";
import { alpha, useTheme } from "@mui/material/styles";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Chip,
  Select,
  MenuItem,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { EditOutlined, SendOutlined, CloseOutlined } from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import { DebouncedInput } from "components/third-party/react-table";
import { db } from "../firebase";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

const UsersList = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useFirebase(); // Get the current user object from the context
  const currentUserRole = user?.role; // Assuming the role is directly on the user object
  const currentUserCompany = user?.company; // Assuming company info is stored on the user object
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
  const [editFormData, setEditFormData] = useState({});

  const queryClient = useQueryClient();

  // Fetch Users with a role of "tech" from the users collection in Firestore
  const {
    data: techUsers,
    isLoading: techUsersLoading,
    error: techUsersError,
  } = useQuery({
    queryKey: ["techUsersList"],
    queryFn: async () => {
      const snapshot = await getDocs(collection(db, "users"));
      return snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((user) => user.role === "tech");
    },
  });

  // Fetch users from Firestore
  const {
    data: users,
    isLoading: usersLoading,
    error: usersError,
  } = useQuery({
    queryKey: ["usersList2"],
    queryFn: async () => {
      const snapshot = await getDocs(collection(db, "users"));
      const allUsersData = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const userData = {
            ...doc.data(),
            id: doc.id,
          };

          // Fetch tech details if 'tech' is a Firestore reference
          const techDetails =
            userData.techRef && typeof userData.techRef === "object"
              ? (await getDoc(userData.techRef)).data()
              : null;
          const techName = techDetails ? techDetails.name : "";

          // Fetch slugGun 'system' details if 'slugGun' is a Firestore reference
          const slugGunDetails =
            userData.slugGunRef && typeof userData.slugGunRef === "object"
              ? await getDoc(userData.slugGunRef)
              : null;
          const slugGunSystem =
            slugGunDetails && slugGunDetails.exists()
              ? `${slugGunDetails.data().machineName} - ${
                  slugGunDetails.data().system
                }`
              : null || "";

          // Fetch company name if 'company' is a Firestore reference
          const companyDetails =
            userData.companyRef && typeof userData.companyRef === "object"
              ? (await getDoc(userData.companyRef)).data()
              : null;
          const companyName = companyDetails ? companyDetails.companyName : "";

          // console.log("Fetched User Data:", {
          //   ...userData,
          //   techName: techName,
          //   slugGunSystem: slugGunSystem,
          //   companyName: companyName,
          // });

          return {
            ...userData,
            techName: techName,
            slugGunSystem: slugGunSystem,
            companyName: companyName,
          };
        })
      );
      return allUsersData;
    },
  });

  // Fetch companies from Firestore
  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useQuery({
    queryKey: ["companies"],
    queryFn: async () => {
      const companiesSnap = collection(db, "companies");
      const companies = await getDocs(companiesSnap);
      return companies.docs;
    },
  });

  const {
    data: systems,
    isLoading: systemsLoading,
    error: systemsError,
  } = useQuery({
    queryKey: ["systems"],
    queryFn: async () => {
      const snapshot = await getDocs(collection(db, "machines"));
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },
  });

  const getRoleChipStyle = (role) => {
    switch (role) {
      case "admin":
        return {
          backgroundColor: alpha(theme.palette.success.main, 0.1),
          color: "#41ae19",
          border: "1px solid #97d67e",
        };
      case "tech":
        return {
          backgroundColor: alpha(theme.palette.error.main, 0.1),
          color: theme.palette.error.main,
          border: "1px solid #f1b0a5",
        };
      case "manager":
        return {
          backgroundColor: alpha(theme.palette.warning.main, 0.1),
          color: "#b88c19",
          border: "1px solid #f2cb64",
        };
      case "foamer":
        return {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          color: theme.palette.primary.main,
          border: "1px solid #b0c3fe",
        };
      case "unassigned":
        return {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[500],
          border: "1px solid #dbdbdb",
        };
      default:
        return {
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[500],
          border: "1px solid #dbdbdb",
        };
    }
  };

  const handleEditClick = (user) => {
    setEditUserId(user.id);
    setEditFormData({
      name: user.name,
      role: user.role,
      companyRef: user.companyRef?.id || "",
      tech: user.techRef?.id || "",
      slugGunSystem: user.slugGunRef?.id || "",
      phone: user.phone || "",
    });
  };

  // Function to handle change in form fields
  const handleFieldChange = (event, fieldName) => {
    const selectedSystem = systems.find(
      (system) => system.id === event.target.value
    );
    setEditFormData((prev) => ({
      ...prev,
      [fieldName]: selectedSystem
        ? doc(db, "machines", selectedSystem.id)
        : null,
      slugGunSystem: selectedSystem
        ? `${selectedSystem.machineName} - ${selectedSystem.system}`
        : "",
    }));
    if (fieldName === "phone") {
      let value = event.target.value;
      // Remove all non-digits
      value = value.replace(/\D/g, "");

      // Format the phone number
      value = value.substring(0, 10); // limit to 10 digits
      let formattedValue = "";
      if (value.length > 6) {
        formattedValue = `${value.slice(0, 3)}-${value.slice(
          3,
          6
        )}-${value.slice(6, 10)}`;
      } else if (value.length > 3) {
        formattedValue = `${value.slice(0, 3)}-${value.slice(3, 6)}`;
      } else {
        formattedValue = value;
      }

      setEditFormData((prev) => ({ ...prev, [fieldName]: formattedValue }));
    } else {
      // Handle other fields normally
      setEditFormData((prev) => ({ ...prev, [fieldName]: event.target.value }));
    }
  };

  // Function to save edits to Firestore and state
  const handleSaveClick = async (userId) => {
    // Ensure we have a valid tech reference or a reference to the 'Unassigned Tech'
    const techRef =
      editFormData.techRef && editFormData.techRef !== ""
        ? doc(db, "users", editFormData.techRef)
        : null;

    const companyRef =
      editFormData.companyRef && editFormData.companyRef !== ""
        ? doc(db, "companies", editFormData.companyRef)
        : null;

    const slugGunRef =
      editFormData.slugGunRef && editFormData.slugGunRef !== ""
        ? doc(db, "machines", editFormData.slugGunRef)
        : null;

    const updatedData = {
      ...editFormData,
      techRef: techRef,
      companyRef: companyRef,
      slugGunRef: slugGunRef,
    };

    try {
      await updateDoc(doc(db, "users", userId), updatedData);
      queryClient.invalidateQueries(["usersList2"]);
    } catch (error) {
      // console.error("Error updating document: ", error);
    }

    setEditUserId(null);
  };

  const handleCancelClick = () => {
    setEditUserId(null);
  };

  const handlePhoneCall = (phoneNumber) => {
    // Display confirmation dialog or prompt user
    if (window.confirm(`Call ${phoneNumber}?`)) {
      // Logic to open user's default phone app or initiate a call (not directly possible in browser)
      // console.log(`Calling phone number: ${phoneNumber}`); // For demonstration purposes
    }
  };

  // Define table columns
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "phone",
        header: "Phone",
        cell: ({ row }) => {
          return editUserId === row.original.id ? (
            // If the user is in edit mode, display a TextField for editing the phone number
            <TextField
              value={editFormData.phone || ""}
              onChange={(e) => handleFieldChange(e, "phone")}
              placeholder="123-456-7890"
              size="small"
              fullWidth
            />
          ) : // If not in edit mode, check if there's a phone number and display it; otherwise, display "Not Assigned"
          row.original.phone ? (
            <Chip
              label={row.original.phone}
              variant="outlined"
              onClick={() => handlePhoneCall(row.original.phone)}
            />
          ) : (
            "Not Assigned"
          ); // Display when no phone number is available
        },
      },
      {
        accessorKey: "role",
        header: "Role",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "techName",
        header: "Tech",
        cell: ({ row }) => {
          if (editUserId === row.original.id) {
            // Show dropdown when editing
            const currentTechId = row.original.techRef?.id || "";

            return (
              <Select
                value={currentTechId}
                onChange={(e) => handleFieldChange(e, "techRef")}
                size="small"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">None</MenuItem>
                {techUsers?.map((techUser) => (
                  <MenuItem key={techUser.id} value={techUser.id}>
                    {techUser.name}
                  </MenuItem>
                ))}
              </Select>
            );
          } else {
            // Show tech name when not editing
            return <span>{row.original.techName || ""}</span>;
          }
        },
      },
      {
        accessorKey: "slugGunSystem", // Accessing the 'system' field
        header: "System",
        cell: ({ row }) => {
          return editUserId === row.original.id ? (
            <Select
              value={editFormData.slugGunRef || ""}
              onChange={(e) => handleFieldChange(e, "slugGunRef")}
              size="small"
              displayEmpty
              fullWidth
            >
              {/* {users?.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.slugGunSystem}
                </MenuItem>
              ))} */}
              {systems &&
                systems.length > 0 &&
                systems.map((system) => (
                  <MenuItem value={system.id} key={system.id}>
                    {system.machineName} - {system.system}{" "}
                    {/* Display machine name and system */}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            row.original.slugGunSystem || ""
          );
        },
      },
      {
        accessorKey: "companyName",
        header: "Company",
        cell: ({ row }) => {
          return editUserId === row.original.id ? (
            <Select
              value={editFormData.companyRef}
              onChange={(e) => handleFieldChange(e, "companyRef")}
              size="small"
            >
              {companies?.map((doc) => (
                <MenuItem value={doc.id} key={doc.id}>
                  {doc.data().companyName}
                </MenuItem>
              ))}
            </Select>
          ) : (
            row.original.companyName || ""
          );
        },
      },
      {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
          <IconButton onClick={() => handleEditClick(row.original)}>
            <EditOutlined />
          </IconButton>
        ),
      },
    ],
    [editUserId, editFormData, techUsers, companies]
  );

  // Define react-table instance
  const table = useReactTable({
    data: users,
    columns,
    state: {
      globalFilter, // now managed as part of table state
      sorting,
      //      pageIndex,
      //      pageSize,
    },
    onGlobalFilterChange: setGlobalFilter, // hook up the onGlobalFilterChange handler
    onSortingChange: setSorting, // hook up the onSortingChange handler
    manualPagination: true, // we'll do server-side pagination
    pageCount: -1, // we don't know pageCount upfront, we'll need to calculate it
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (usersLoading || companiesLoading) return <div>Loading...</div>;
  if (usersError || companiesError)
    return <div>Error: {usersError?.message || companiesError?.message}</div>;

  // Render table with @tanstack/react-table components
  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div>
          {/* Table layout */}
          <Table stickyHeader>
            {!isSmallScreen && (
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
            )}

            <TableBody>
              {(users ?? []).map((user) => (
                <TableRow
                  key={user.id}
                  style={{ display: matches ? "block" : "table-row" }}
                >
                  {/* Render Name */}
                  <TableCell
                    style={{
                      fontWeight: 800,
                      display: matches ? "block" : "table-cell",
                      width: matches
                        ? "100%"
                        : "auto" /* paddingBottom: matches ? '12px' : 'initial', borderBottom: 'none' */,
                    }}
                  >
                    {editUserId === user.id ? (
                      <TextField
                        value={editFormData.name}
                        onChange={(e) => handleFieldChange(e, "name")}
                        size="small"
                      />
                    ) : (
                      user.name
                    )}
                  </TableCell>

                  {/* Render Email */}
                  <TableCell
                    style={{
                      display: matches ? "block" : "table-cell",
                      width: matches ? "100%" : "auto",
                      paddingLeft: matches
                        ? "24px"
                        : "auto" /* paddingBottom: matches ? '12px' : 'initial', borderBottom: 'none' */,
                    }}
                  >
                    {user.email}
                  </TableCell>

                  {/* Render Phone */}
                  <TableCell
                    style={{
                      display: matches ? "block" : "table-cell",
                      width: matches ? "100%" : "auto",
                      paddingLeft: matches
                        ? "24px"
                        : "auto" /* paddingBottom: matches ? '12px' : 'initial', borderBottom: 'none' */,
                    }}
                  >
                    {editUserId === user.id ? (
                      <TextField
                        value={editFormData.phone || ""}
                        onChange={(e) => handleFieldChange(e, "phone")}
                        placeholder="123-456-7890"
                        size="small"
                        fullWidth
                      />
                    ) : user.phone ? (
                      <Chip
                        label={user.phone}
                        variant="outlined"
                        onClick={() => handlePhoneCall(user.phone)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      display: matches ? "block" : "table-cell",
                      width: matches ? "100%" : "auto",
                      paddingLeft: matches
                        ? "24px"
                        : "auto" /* paddingBottom: matches ? '12px' : 'initial', borderBottom: 'none' */,
                    }}
                  >
                    {editUserId === user.id ? (
                      <Select
                        value={editFormData.role}
                        onChange={(e) => handleFieldChange(e, "role")}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value="admin">admin</MenuItem>
                        <MenuItem value="tech">tech</MenuItem>
                        <MenuItem value="manager">manager</MenuItem>
                        <MenuItem value="foamer">foamer</MenuItem>
                        <MenuItem value="unassigned">unassigned</MenuItem>
                      </Select>
                    ) : (
                      <Chip
                        label={user.role}
                        style={getRoleChipStyle(user.role)}
                        variant="outlined"
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      display: matches ? "block" : "table-cell",
                      width: matches ? "100%" : "auto",
                      paddingLeft: matches
                        ? "24px"
                        : "auto" /* paddingBottom: matches ? '12px' : 'initial', borderBottom: 'none' */,
                    }}
                  >
                    {editUserId === user.id ? (
                      <Select
                        value={editFormData.techRef || ""}
                        onChange={(e) => handleFieldChange(e, "techRef")}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        <MenuItem value="">None</MenuItem>
                        {techUsers?.map((techUser) => (
                          <MenuItem key={techUser.id} value={techUser.id}>
                            {techUser.name}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <span>{user.techName || ""}</span>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      display: matches ? "block" : "table-cell",
                      width: matches ? "100%" : "auto",
                      paddingLeft: matches ? "24px" : "auto",
                    }}
                  >
                    {editUserId === user.id ? (
                      <Select
                        value={editFormData.slugGunRef || ""}
                        onChange={(e) => handleFieldChange(e, "slugGunRef")}
                        size="small"
                        displayEmpty
                        fullWidth
                      >
                        {systems &&
                          systems.length > 0 &&
                          systems.map((system) => (
                            <MenuItem value={system.id} key={system.id}>
                              {system.machineName} - {system.system}{" "}
                              {/* Display machine name and system */}
                            </MenuItem>
                          ))}
                        {/* {users?.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.slugGunSystem}
                          </MenuItem>
                        ))} */}
                      </Select>
                    ) : (
                      user.slugGunSystem || ""
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      display: matches ? "block" : "table-cell",
                      width: matches ? "100%" : "auto",
                      paddingLeft: matches ? "24px" : "auto",
                    }}
                  >
                    {editUserId === user.id ? (
                      <Select
                        value={editFormData.companyRef}
                        onChange={(e) => handleFieldChange(e, "companyRef")}
                        size="small"
                      >
                        {companies?.map((doc) => (
                          <MenuItem value={doc.id} key={doc.id}>
                            {doc.data().companyName}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      user.companyName || ""
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      display: matches ? "block" : "table-cell",
                      width: matches ? "100%" : "auto",
                      /* paddingBottom: matches ? '20px' : 'initial', */ textAlign:
                        "right",
                      borderBottom: isSmallScreen
                        ? "5px solid #eee"
                        : "1px solid #eee",
                    }}
                  >
                    {editUserId === user.id ? (
                      <>
                        <IconButton
                          color="error"
                          name="cancel"
                          onClick={handleCancelClick}
                        >
                          <CloseOutlined />
                        </IconButton>
                        <IconButton
                          color="success"
                          name="save"
                          onClick={() => handleSaveClick(user.id)}
                        >
                          <SendOutlined />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        color="primary"
                        name="edit"
                        onClick={() => handleEditClick(user)}
                      >
                        <EditOutlined />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </DndProvider>
  );
};

export default UsersList;
