import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore"; // Import necessary functions
import { db } from "../firebase";
import { useFirebase } from "../contexts/FirebaseContext";
import {
  Grid,
  TextField,
  Button,
  Typography,
  FormHelperText,
  Divider,
  CircularProgress,
  InputLabel,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import MainCard from "components/MainCard";
import AnimateButton from "components/@extended/AnimateButton";
import { useParams, useSearchParams } from "react-router-dom";

// Validation schema
const validationSchema = yup.object({
  durationBagShot: yup
    .number()
    .required("Duration Bag Shot is required")
    .positive()
    .integer(),
  machineName: yup.string().required("Machine Name is required"),
  companyName: yup.string().required("Company Name is required"),
  nickname: yup.string(),
  machineType: yup.string().required("Machine Type is required"),
  output: yup.number().required("Output is required").positive(),
  outputLowerLimit: yup
    .number()
    .required("Output Lower Limit is required")
    .positive(),
  outputUpperLimit: yup
    .number()
    .required("Output Upper Limit is required")
    .positive(),
  ratioLowerLimit: yup
    .number()
    .required("Ratio Lower Limit is required")
    .positive(),
  ratioUpperLimit: yup
    .number()
    .required("Ratio Upper Limit is required")
    .positive(),
  system: yup.string().required("System is required"),
  temperatureLowerLimit: yup
    .number()
    .required("Temperature Lower Limit is required")
    .positive(),
  temperatureUpperLimit: yup
    .number()
    .required("Temperature Upper Limit is required")
    .positive(),
});

const SetupGun = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [machineData, setMachineData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [companySelection, setCompanySelection] = useState("new");
  const [companies, setCompanies] = useState([]);
  const [searchParams] = useSearchParams();
  const urlCompanyName = searchParams.get("companyName");
  const machineId = searchParams.get("machineId");
  const { user } = useFirebase();
  // const { machineId } = { machineId: "9B9M6r6KG1TMFWIwf3JT" };

  useEffect(() => {
    const fetchCompanies = async () => {
      if (user && user.uid) {
        const techRef = doc(db, "users", user.uid);
        const q = query(
          collection(db, "companies"),
          where("techRef", "==", techRef)
        );
        const querySnapshot = await getDocs(q);
        const companiesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().companyName,
        }));
        setCompanies(companiesData);
      }
    };

    fetchCompanies();
  }, [user]);

  useEffect(() => {
    if (machineId) {
      const fetchSystemData = async () => {
        const systemDoc = doc(db, "machines", machineId);
        const systemSnapshot = await getDoc(systemDoc);
        if (systemSnapshot.exists()) {
          const systemData = systemSnapshot.data();
          console.log("Fetched system data:", systemData); // Log the fetched data
          setMachineData(systemData); // Set the machine data state
          formik.setValues({
            durationBagShot: systemData.durationBagShot || "",
            machineName: systemData.machineName || "",
            companyName: systemData.companyName || "",
            nickname: systemData.nickname || "",
            machineType: systemData.machineType || "",
            output: systemData.output || "",
            outputLowerLimit: systemData.outputLowerLimit || "",
            outputUpperLimit: systemData.outputUpperLimit || "",
            ratioLowerLimit: systemData.ratioLowerLimit || "",
            ratioUpperLimit: systemData.ratioUpperLimit || "",
            system: systemData.system || "",
            temperatureLowerLimit: systemData.temperatureLowerLimit || "",
            temperatureUpperLimit: systemData.temperatureUpperLimit || "",
          });
          setIsEditing(true);
        }
      };

      fetchSystemData();
    }
  }, [machineId]);

  const formik = useFormik({
    initialValues: {
      durationBagShot: "",
      machineName: "",
      companyName: urlCompanyName || "", // Use companyName from URL parameter
      nickname: "",
      machineType: "",
      output: "",
      outputLowerLimit: "",
      outputUpperLimit: "",
      ratioLowerLimit: "",
      ratioUpperLimit: "",
      system: "",
      temperatureLowerLimit: "",
      temperatureUpperLimit: "",
    },
    validationSchema,
    enableReinitialize: true, // Allow form to reinitialize with new values
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      let companyRef;

      if (companySelection === "new") {
        const companyQuery = query(
          collection(db, "companies"),
          where("companyName", "==", values.companyName)
        );
        const companySnapshot = await getDocs(companyQuery);

        if (companySnapshot.empty) {
          console.log("No existing company found, creating new one.");
          const newCompanyRef = await addDoc(collection(db, "companies"), {
            companyName: values.companyName,
            techName: user.name,
            techRef: doc(db, "users", user.uid),
            timestamp: serverTimestamp(),
          });
          companyRef = newCompanyRef;
        } else {
          console.log("Existing company found, using existing reference.");
          companyRef = companySnapshot.docs[0].ref;
        }
      } else {
        const selectedCompany = companies.find(
          (company) => company.name === values.companyName
        );
        if (selectedCompany) {
          companyRef = doc(db, "companies", selectedCompany.id);
        } else {
          console.error("Selected company not found in user's companies.");
          setSubmitting(false);
          return;
        }
      }

      const docData = {
        ...values,
        companyRef: companyRef,
        timestamp: serverTimestamp(),
        tech: user.name,
        techRef: doc(db, "users", user.uid),
      };

      try {
        if (isEditing) {
          const systemDoc = doc(db, "machines", machineId);
          await updateDoc(systemDoc, docData);
          console.log("Machine data updated successfully");
        } else {
          await addDoc(collection(db, "machines"), docData);
          console.log("Machine data submitted successfully");
        }
        setIsSubmitted(true);
        resetForm();
      } catch (error) {
        console.error("Error writing document to Firestore", error);
      }
      setSubmitting(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ mb: 4 }}>
            {isEditing ? "Edit Gun and System" : "Setup Gun and System"}
          </Typography>
        </Grid>

        {/* Add buttons for company selection */}
        <Grid item xs={12} sm={12} lg={8} sx={{ margin: "auto", mb: 4 }}>
          <Button
            variant={companySelection === "current" ? "contained" : "outlined"}
            onClick={() => setCompanySelection("current")}
          >
            Current Company
          </Button>
          <Button
            variant={companySelection === "new" ? "contained" : "outlined"}
            onClick={() => setCompanySelection("new")}
            sx={{ ml: 2 }}
          >
            Add New Company
          </Button>
        </Grid>

        {isSubmitted ? (
          <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
            <MainCard
              raised
              variant="outlined"
              sx={{
                backgroundColor: "#edf4eb",
                color: "#33831c",
                padding: "10px",
              }}
            >
              <Typography variant="h1" sx={{ mb: 1 }}>
                Success!
              </Typography>
              <Typography variant="h2" sx={{ mb: 4, color: "#86ac7b" }}>
                The System is {isEditing ? "updated" : "setup"}!
              </Typography>
              <Typography variant="h4" sx={{ color: "#86ac7b" }}>
                You can begin associating users with this company and system.
              </Typography>
            </MainCard>
          </Grid>
        ) : (
          <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
            <MainCard raised sx={{ p: 2 }}>
              <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
                {/* Form Fields Setup */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={4}
                  sx={{ pt: { xs: 2, sm: "0 !important" } }}
                >
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Company Name:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  {companySelection === "current" ? (
                    <Select
                      fullWidth
                      id="companyName"
                      name="companyName"
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.companyName &&
                        Boolean(formik.errors.companyName)
                      }
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Company
                      </MenuItem>
                      {companies.map((company) => (
                        <MenuItem key={company.id} value={company.name}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      fullWidth
                      id="companyName"
                      name="companyName"
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.companyName &&
                        Boolean(formik.errors.companyName)
                      }
                      helperText={
                        formik.touched.companyName && formik.errors.companyName
                      }
                    />
                  )}
                  <FormHelperText>
                    Associate this machine with a company.
                  </FormHelperText>
                </Grid>

                {/* ... Rest of the form fields */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={4}
                  sx={{ pt: { xs: 2, sm: "0 !important" } }}
                >
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Nickname:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="nickname"
                    name="nickname"
                    value={formik.values.nickname}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nickname && Boolean(formik.errors.nickname)
                    }
                    helperText={
                      formik.touched.nickname && formik.errors.nickname
                    }
                  />
                  <FormHelperText>
                    Optional nickname if employees know reference this
                    system/equipment by a nickname.
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={4}
                  sx={{ pt: { xs: 2, sm: "0 !important" } }}
                >
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Equipment Serial Number:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="machineName"
                    name="machineName"
                    value={formik.values.machineName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.machineName &&
                      Boolean(formik.errors.machineName)
                    }
                    helperText={
                      formik.touched.machineName && formik.errors.machineName
                    }
                  />
                  <FormHelperText>
                    Should have a reserved ID (ex. SP6001)
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid
                  item
                  xs={12}
                  sm={3}
                  lg={4}
                  sx={{ pt: { xs: 2, sm: "0 !important" } }}
                >
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Equipment Type:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="machineType"
                    name="machineType"
                    value={formik.values.machineType}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.machineType &&
                      Boolean(formik.errors.machineType)
                    }
                    helperText={
                      formik.touched.machineType && formik.errors.machineType
                    }
                  />
                  <FormHelperText>
                    Gun Output and Length. (ex: 30x45)
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    System being used:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="system"
                    name="system"
                    value={formik.values.system}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.system && Boolean(formik.errors.system)
                    }
                    helperText={formik.touched.system && formik.errors.system}
                  />
                  <FormHelperText>
                    Name of the system (ex. EcoFoam HD)
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={12} lg={12} sx={{ mb: 4, mt: 4 }}>
                  <Divider />
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Bag Shot Duration:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="durationBagShot"
                    name="durationBagShot"
                    value={formik.values.durationBagShot}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.durationBagShot &&
                      Boolean(formik.errors.durationBagShot)
                    }
                    helperText={
                      formik.touched.durationBagShot &&
                      formik.errors.durationBagShot
                    }
                  />
                  <FormHelperText>
                    Please enter the duration in seconds
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Output:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="output"
                    name="output"
                    value={formik.values.output}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.output && Boolean(formik.errors.output)
                    }
                    helperText={formik.touched.output && formik.errors.output}
                  />
                  <FormHelperText>
                    Please enter output in PPM (Pounds Per Minute)
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Output - Lower Limit:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="outputLowerLimit"
                    name="outputLowerLimit"
                    value={formik.values.outputLowerLimit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.outputLowerLimit &&
                      Boolean(formik.errors.outputLowerLimit)
                    }
                    helperText={
                      formik.touched.outputLowerLimit &&
                      formik.errors.outputLowerLimit
                    }
                  />
                  <FormHelperText>
                    Please enter output in PPM (Pounds Per Minute)
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Output - Upper Limit:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="outputUpperLimit"
                    name="outputUpperLimit"
                    value={formik.values.outputUpperLimit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.outputUpperLimit &&
                      Boolean(formik.errors.outputUpperLimit)
                    }
                    helperText={
                      formik.touched.outputUpperLimit &&
                      formik.errors.outputUpperLimit
                    }
                  />
                  <FormHelperText>
                    Please enter output in PPM (Pounds Per Minute)
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Ratio - Lower Limit:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="ratioLowerLimit"
                    name="ratioLowerLimit"
                    value={formik.values.ratioLowerLimit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ratioLowerLimit &&
                      Boolean(formik.errors.ratioLowerLimit)
                    }
                    helperText={
                      formik.touched.ratioLowerLimit &&
                      formik.errors.ratioLowerLimit
                    }
                  />
                  <FormHelperText>Enter the lower end of ratio.</FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Ratio - Upper Limit:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="ratioUpperLimit"
                    name="ratioUpperLimit"
                    value={formik.values.ratioUpperLimit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ratioUpperLimit &&
                      Boolean(formik.errors.ratioUpperLimit)
                    }
                    helperText={
                      formik.touched.ratioUpperLimit &&
                      formik.errors.ratioUpperLimit
                    }
                  />
                  <FormHelperText>Enter the upper end of ratio.</FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Temperature - Lower Limit:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="temperatureLowerLimit"
                    name="temperatureLowerLimit"
                    value={formik.values.temperatureLowerLimit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.temperatureLowerLimit &&
                      Boolean(formik.errors.temperatureLowerLimit)
                    }
                    helperText={
                      formik.touched.temperatureLowerLimit &&
                      formik.errors.temperatureLowerLimit
                    }
                  />
                  <FormHelperText>
                    Enter the lower end of temperature in fahrenheit.
                  </FormHelperText>
                </Grid>
                {/* ---- */}
                <Grid item xs={12} sm={3} lg={4}>
                  <InputLabel
                    sx={{
                      textAlign: { xs: "left", sm: "right" },
                      fontWeight: 900,
                    }}
                  >
                    Temperature - Upper Limit:
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={9} lg={6}>
                  <TextField
                    fullWidth
                    id="temperatureUpperLimit"
                    name="temperatureUpperLimit"
                    value={formik.values.temperatureUpperLimit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.temperatureUpperLimit &&
                      Boolean(formik.errors.temperatureUpperLimit)
                    }
                    helperText={
                      formik.touched.temperatureUpperLimit &&
                      formik.errors.temperatureUpperLimit
                    }
                  />
                  <FormHelperText>
                    Enter the upper end of temperature in fahrenheit.
                  </FormHelperText>
                </Grid>
                {/* ---- */}
              </Grid>
              <Grid item xs={12} sx={{ mb: 4 }}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end">
                  <AnimateButton>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? (
                        <CircularProgress size={24} />
                      ) : isEditing ? (
                        "Update"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </MainCard>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default SetupGun;
