import React, {
  createContext,
  useState,
  useEffect,
  useReducer,
  useContext,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "./firebaseOperations";
import { LOGIN, LOGOUT, LOADING } from "./auth-reducer/actions";
import authReducer from "./auth-reducer/auth";
import Loader from "../components/Loader";
import useFetchUserData from "hooks/useFetchUserData";
import { useQuery } from "@tanstack/react-query";

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    loading: true,
  });
  const [error, setError] = useState(null);
  // console.log("Inside FirebaseContext.js - state:", state);
  const { userData, loading: userDataLoading } = useFetchUserData(
    state.user?.uid
  );
  // console.log(
  //   "Inside FirebaseContext.js - userData:",
  //   userData,
  //   userDataLoading
  // );
  // console.log("Inside FirebaseContext.js - userDataLoading:", userDataLoading);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      /* setUser(user); */
      if (user) {
        // console.log("Inside FirebaseContext.js - onAuthStateChanged:", user);

        // Fetch user data from Firestore
        /* console.log(
          "Inside FirebaseContext.js - onAuthStateChanged - User ID:",
          user.uid
        );
        console.log(
          "Inside FirebaseContext.js - onAuthStateChanged - User Email:",
          user.email
        ); */
        const userRef = doc(db, `users/${user.uid}`);
        /* console.log(
          "Inside FirebaseContext.js - onAuthStateChanged - User Ref:",
          userRef.path
        ); */

        const docSnap = await getDoc(userRef);
        /* console.log(
          "Inside FirebaseContext.js - onAuthStateChanged - User DocSnap:",
          docSnap
        ); */
        if (docSnap.exists()) {
          const userData = docSnap.data();
          /* console.log(
            "Inside FirebaseContext.js - onAuthStateChanged - User Data:",
            userData
          ); */
          dispatch({
            type: LOGIN,
            payload: { isLoggedIn: true, user: { ...user, ...userData } },
          });
        } else {
          /* console.log(
            "Inside FirebaseContext.js - onAuthStateChanged - No such document!"
          ); */
          dispatch({ type: LOGOUT });
        }
        dispatch({ type: "SET_INITIALIZED", payload: true });
        // dispatch({
        //   type: LOGIN,
        //   payload: { isLoggedIn: true, user },
        // });
      } else {
        dispatch({ type: LOGOUT });
      }
    });

    return () => unsubscribe();
  }, []);

  // Action creators for sign in, sign out, etc
  const firebaseSignIn = (email, password) => {
    dispatch({ type: LOADING });
    // try {
    return signInWithEmailAndPassword(email, password);
    // } catch (error) {
    //   setError(error);
    //   console.error("Error during sign in: ", error)
    // }
  };
  const firebaseRegister = (email, password) => {
    dispatch({ type: LOADING });
    return createUserWithEmailAndPassword(email, password);
  };
  const firebaseSignOut = () => {
    dispatch({ type: LOADING });
    return signOut();
  };
  const firebaseResetPassword = (email) => sendPasswordResetEmail(email);

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        userLoading: state.loading,
        firebaseRegister,
        firebaseSignIn,
        firebaseSignOut,
        firebaseResetPassword,
        userData,
        userDataLoading,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);
