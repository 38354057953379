import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useFirebase } from "../contexts/FirebaseContext";
import { rolePermissions } from "../config/roles";
import { alpha, useTheme } from "@mui/material/styles";
import { getRoleChipStyle } from "../utils/styleUtils";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Chip,
  Select,
  MenuItem,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";
import {
  EditOutlined,
  SendOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import { DebouncedInput } from "components/third-party/react-table";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { useUnassignedUsers } from "../hooks/useUnassignedUsers";
import { usersListQuery } from "../queries/usersList";
import useFormState from "../hooks/useFormState";
import { db } from "../firebase";

import { produce } from "immer";
import { PhoneInput } from "./PhoneInput";
import { systemQueryOptions } from "queries/system";
import "../assets/css/styles.css";

const UnassignedUsersList = ({ setUsers, companyRef }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useFirebase();
  const currentUserRole = user?.role;
  const currentUserCompany = user?.company;
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [editUserId, setEditUserId] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: "",
    phone: "",
    role: "",
    slugGunRef: {},
    // company: "",
  });

  const queryClient = useQueryClient();

  const {
    data: unassignedUsers,
    isLoading: isLoadingUnassignedUsers,
    error: unassignedUsersError,
  } = useUnassignedUsers(user);

  const systemQuery = useQuery(systemQueryOptions());

  const handleEditClick = (user) => {
    setEditUserId(user.id);
    setEditFormData({
      name: user.name,
      role: user.role,
      // company: user.company,
      // tech: user.tech?.id || "",
      slugGunRef: user.slugGunRef,
      phone: user.phone,
    });
    console.log(
      "Editing initiated for user:",
      user.id,
      "Company Reference:",
      user.company
    );
  };

  const handleAssociateWithCompany = useCallback(
    async (userId) => {
      if (!editUserId || editUserId !== userId) {
        console.error(
          "Attempt to associate outside edit mode or for different user"
        );
        return;
      }

      try {
        await updateDoc(doc(db, "users", userId), { companyRef: companyRef });
        console.log("User associated with company successfully!");
        queryClient.invalidateQueries(["usersList"]);
      } catch (error) {
        console.error("Error associating user with company:", error);
      }
    },
    [companyRef, editUserId]
  );

  const handleAssociateWithTech = async (userId) => {
    if (!editUserId || editUserId !== userId) {
      console.error(
        "Attempt to associate outside edit mode or for different user"
      );
      return;
    }

    try {
      await updateDoc(doc(db, "users", userId), {
        techRef: doc(db, "users", user.uid),
      });
      console.log("User associated with tech successfully!");
      queryClient.invalidateQueries(["usersList"]);
    } catch (error) {
      console.error("Error associating user with tech:", error);
    }
  };

  const handleFieldChange = useCallback(
    (event, fieldName) => {
      // console.log(event)
      // if (fieldName === "phone") {
      //   let value = event.target.value;
      //   value = value.replace(/\D/g, "");
      //   value = value.substring(0, 10);
      //   let formattedValue = "";
      //   if (value.length > 6) {
      //     formattedValue = `${value.slice(0, 3)}-${value.slice(
      //       3,
      //       6
      //     )}-${value.slice(6, 10)}`;
      //   } else if (value.length > 3) {
      //     formattedValue = `${value.slice(0, 3)}-${value.slice(3, 6)}`;
      //   } else {
      //     formattedValue = value;
      //   }

      // } else {
      const selectedSystem = systemQuery.data.find(
        (system) => system.ref.id === event.target.value
      );
      setEditFormData((prev) => ({
        ...prev,
        [fieldName]: selectedSystem ? selectedSystem.ref : null,
        slugGun: selectedSystem
          ? `${selectedSystem.data().machineName} - ${
              selectedSystem.data().system
            }`
          : "",
      }));
      console.log("Updating ", fieldName, "to", event.target.value);
      setEditFormData((prev) => ({ ...prev, [fieldName]: event.target.value }));
      // setEditFormData(produce((draft) => draft[fieldName] = event.target.value));
      // }
    },
    [systemQuery.data]
  );

  const handleSaveClick = async (userId) => {
    console.log(
      "Attempting to update user with ID:",
      userId,
      "Data:",
      editFormData
    );

    // const techRef = editFormData.tech
    //   ? doc(db, "users", editFormData.tech)
    //   : doc(db, "users", "YxN1PjHv3VvWxZlTfkOA");

    const updatedData = {
      ...editFormData,
      // techRef,
    };

    delete updatedData.company;

    for (let key in updatedData) {
      if (
        updatedData.hasOwnProperty(key) &&
        typeof updatedData[key] === "undefined"
      ) {
        delete updatedData[key];
      }
    }

    try {
      await updateDoc(doc(db, "users", userId), updatedData);
      queryClient.invalidateQueries(["usersList"]);
      console.log("Document successfully updated");
      setEditUserId(null);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleCancelClick = () => {
    setEditUserId(null);
  };

  const handlePhoneCall = (phoneNumber) => {
    if (window.confirm(`Call ${phoneNumber}?`)) {
      console.log(`Calling phone number: ${phoneNumber}`);
    }
  };

  const handleCellBlur = (column, value) =>
    handleFieldChange({ target: { value } }, column.id);

  const cellBlurHandler = (column, value) => () =>
    handleCellBlur(column, value);

  const defaultColumn = useMemo(
    () => ({
      cell: (props) => {
        const { getValue, row, column } = props;
        const initialValue = getValue();
        const value = initialValue;

        if (editUserId === row.original.id) {
          // switch (column.id) {
          //   case "phone":
          //     return (
          //       <TextField
          //         value={value}
          //         onChange={(e) => handleFieldChange(e, column.id)}
          //         onBlur={onBlur}
          //         placeholder="123-456-7890"
          //         size="small"
          //         fullWidth
          //       />
          //     );
          //   case "name":
          //     return (
          //       <TextField
          //         value={value}
          //         onChange={(e) => handleFieldChange(e, column.id)}
          //         onBlur={onBlur}
          //         size="small"
          //       />
          //     );
          //   case "role":
          //     return (
          //       <Select
          //         value={value}
          //         onChange={(e) => handleFieldChange(e, column.id)}
          //         onBlur={onBlur}
          //         size="small"
          //         fullWidth
          //       >
          //         {["admin", "tech", "manager", "foamer", "unassigned"].map(
          //           (role) => (
          //             <MenuItem value={role} key={role}>
          //               <Chip
          //                 label={role}
          //                 style={getRoleChipStyle(role, theme)}
          //                 variant="outlined"
          //               />
          //             </MenuItem>
          //           )
          //         )}
          //       </Select>
          //     );
          //   case "slugGunSystem":
          //     return (
          //       <TextField
          //         value={value}
          //         onChange={(e) => handleFieldChange(e, column.id)}
          //         onBlur={onBlur}
          //         size="small"
          //       />
          //     );
          //   default:
          //     return flexRender(column.columnDef.cell, row.getContext());
          // }
          if (column.columnDef.editCell)
            return column.columnDef.editCell(props);
          else
            return (
              <TextField
                value={editFormData[column.id]}
                onChange={(e) => handleFieldChange(e, column.id)}
                // onBlur={onBlur}
                size="small"
              />
            );
        } else {
          // return flexRender(column.columnDef.cell, row.getContext());
          return column.columnDef.displayCell(props);
        }
      },
    }),
    [editUserId, handleFieldChange]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        displayCell: (props) => (
          <span className="bold-name">{props.getValue()}</span>
        ),
        editCell: ({ getValue, column }) => (
          <TextField
            value={editFormData.name}
            onChange={(e) => handleFieldChange(e, column.id)}
            // onBlur={(e) => handle}
            size="small"
          />
        ),
      },
      {
        accessorKey: "email",
        header: "Email",
        headerClassName: "hide-on-mobile",
        className: "hide-on-mobile",
        cell: ({ getValue }) =>
          getValue() ? (
            <span>
              <a
                href={`mailto:${getValue()}`}
                className="text"
                style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
              >
                {getValue()}
              </a>
              <a href={`mailto:${getValue()}`} className="icon">
                <IconButton color="primary" aria-label="email">
                  <MailOutlined />{" "}
                </IconButton>
              </a>
            </span>
          ) : (
            ""
          ),
      },
      {
        accessorKey: "phone",
        header: "Phone",
        headerClassName: "hide-on-mobile",
        className: "hide-on-mobile",
        displayCell: (props) =>
          props.getValue() ? (
            <span>
              <a
                href={"tel:" + props.getValue()}
                className="text"
                style={{ color: "rgb(22, 119, 255)", textDecoration: "none" }}
              >
                {props.getValue()}
              </a>
              <a href={"tel:" + props.getValue()} className="icon">
                <IconButton color="primary" aria-label="call">
                  <PhoneOutlined />
                </IconButton>
              </a>
            </span>
          ) : (
            ""
          ),
        editCell: ({ getValue, column }) => (
          <PhoneInput
            value={editFormData.phone}
            onChange={(v, e) =>
              handleFieldChange({ target: { value: v } }, column.id)
            }
            size="small"
          />
        ),
      },
      {
        accessorKey: "role",
        displayCell: (props) => (
          <Chip
            label={props.getValue()}
            style={getRoleChipStyle(props.getValue(), theme)}
            variant="outlined"
            size="small"
          />
        ),
        editCell: ({ column }) => (
          <Select
            value={editFormData.role}
            onChange={(e) => handleFieldChange(e, column.id)}
            size="small"
            fullWidth
          >
            {["manager", "foamer", "unassigned"].map((role) => (
              <MenuItem value={role} key={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        accessorKey: "tech",
        header: "Tech",
        cell: (props) => {
          const unassignedUserName = unassignedUsers?.find(
            (user) => user.id === props.row.original.tech?.id
          )?.name;
          return (
            <>
              <span>{props.getValue()?.name || ""}</span>
              {editUserId === props.row.original.id && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAssociateWithTech(props.row.original.id);
                  }}
                  title="Associate with tech"
                  aria-label="Associate with tech"
                >
                  <PlusCircleOutlined />
                </IconButton>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "slugGun",
        header: "System",
        displayCell: (props) => <span>{props.getValue()?.system || ""}</span>,
        editCell: ({ column, getValue }) => {
          console.log("Select", editFormData.slugGunRef);
          return (
            <Select
              defaultValue={getValue()?.id}
              value={editFormData.slugGunRef?.id}
              onChange={(e) => {
                console.log(e);
                handleFieldChange(
                  {
                    target: {
                      value: systemQuery.data.find(
                        (machine) => machine.ref.id === e.target.value
                      ).ref,
                    },
                  },
                  "slugGunRef"
                );
              }}
              size="small"
              fullWidth
            >
              {systemQuery.data?.map((machine) => (
                <MenuItem value={machine.ref.id} key={machine.ref.id}>
                  {machine.data().machineName} - {machine.data().system}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      {
        accessorKey: "company",
        header: "Company",
        cell: (props) => (
          <div>
            {props.getValue()?.companyName || ""}
            {editUserId === props.row.original.id && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleAssociateWithCompany(props.row.original.id);
                }}
                aria-label="Associate with company"
              >
                <PlusCircleOutlined />
              </IconButton>
            )}
          </div>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        cell: (props) => (
          <>
            {editUserId === props.row.original.id ? (
              <>
                <IconButton
                  color="error"
                  name="cancel"
                  onClick={handleCancelClick}
                >
                  <CloseOutlined />
                </IconButton>
                <IconButton
                  color="success"
                  name="save"
                  onClick={() => handleSaveClick(props.row.original.id)}
                >
                  <SendOutlined />
                </IconButton>
              </>
            ) : (
              <IconButton
                color="primary"
                name="edit"
                onClick={() => handleEditClick(props.row.original)}
              >
                <EditOutlined />
              </IconButton>
            )}
          </>
        ),
      },
    ],
    [
      editFormData,
      editUserId,
      unassignedUsers,
      currentUserRole,
      handleAssociateWithCompany,
      handleAssociateWithTech,
      handleEditClick,
      handleCancelClick,
      handleSaveClick,
      handlePhoneCall,
    ]
  );
  const table = useReactTable({
    data: unassignedUsers,
    columns,
    defaultColumn,
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: -1,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  if (isLoadingUnassignedUsers) {
    return <div>Loading...</div>;
  }
  if (unassignedUsersError) {
    const errorMessage = unassignedUsersError?.message;
    return <div>Error: {errorMessage}</div>;
  }
  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Table stickyHeader>
          <TableHead className="hide-on-mobile">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const isHideOnMobile =
                    header.id === "tech" ||
                    header.id === "slugGun" ||
                    header.id === "phone" ||
                    header.id === "email";
                  return (
                    <TableCell
                      key={header.id}
                      className={
                        isHideOnMobile ? "hide-on-mobile" : "default-class"
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const isHideOnMobile =
                    cell.column.id === "tech" ||
                    cell.column.id === "slugGun" ||
                    cell.column.id === "phone" ||
                    cell.column.id === "email";
                  return (
                    <TableCell
                      key={cell.id}
                      className={
                        isHideOnMobile ? "hide-on-mobile" : "default-class"
                      }
                      style={{
                        display: "table-cell",
                        width: "auto",
                        paddingLeft: "auto",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </DndProvider>
  );
};

export default UnassignedUsersList;
