import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "contexts/FirebaseContext";
import { useFormik } from "formik";
import * as yup from "yup";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../firebase";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import AnimateButton from "components/@extended/AnimateButton";
import ResultCard from "./ResultCard";
import { calculateRatioData, prepareDocData } from "../utils/ratioUtils";
import { fetchSlugGunSystemData } from "../utils/dataFetchers";
import ErrorAlert from "./ErrorAlert";
import PreventBackNavigation from "./PreventBackNavigation";

// Define your validation schema
const validationSchema = yup.object({
  ISOWeight: yup.number().required("ISO Weight is required"),
  PolyWeight: yup.number().required("Poly Weight is required"),
  bagWeight: yup.number().required("Bag Weight is required"),
});

const SecondFormComponent = () => {
  const navigate = useNavigate();
  const { user } = useFirebase();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDetails, setErrorDetails] = useState({
    ratio: false,
    throughputDrop: false,
    ppmOutput: false,
    visualCheck: false,
  });
  const [docId, setDocId] = useState(null);
  const [slugGunSystemData, setSlugGunSystemData] = useState(null);
  const [isSlugGunLoading, setIsSlugGunLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user?.slugGunRef) {
        setIsSlugGunLoading(true);
        const data = await fetchSlugGunSystemData(user.slugGunRef);
        setSlugGunSystemData(data);
        setIsSlugGunLoading(false);
      }
    };

    fetchData();

    try {
      const storedErrorDetails = JSON.parse(
        localStorage.getItem("errorDetails")
      );
      if (storedErrorDetails) {
        setErrorDetails(storedErrorDetails);
        setIsError(true);
        console.log("Stored error details:", storedErrorDetails);
      }
    } catch (e) {
      console.error("Error parsing error details from localStorage:", e);
    }
  }, [user?.slugGunRef]);

  const formik = useFormik({
    initialValues: {
      ISOWeight: "",
      PolyWeight: "",
      bagWeight: "",
      visualCheck: "yes", // Default value
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error("User not authenticated");

        const ratioData = calculateRatioData(
          values,
          slugGunSystemData,
          slugGunSystemData?.durationBagShot || 0
        );
        const docData = prepareDocData(
          values,
          user,
          currentUser,
          slugGunSystemData,
          ratioData,
          "secondForm"
        );

        console.log(ratioData);

        const docRef = await addDoc(collection(db, "formEntries"), docData);
        setDocId(docRef.id);

        setIsSubmitted(true);

        if (ratioData.isOffRatio) {
          console.log("Navigating to third form");
          navigate("/dashboard/foamer/third-form");
        } else {
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error("Error writing document to Firestore", error);
        setIsError(true);
        setErrorMessage(
          "An error occurred while submitting the form: " + error.message
        );
        setIsSubmitted(false);
      }
    },
  });

  const gunNumber = isSlugGunLoading
    ? "Loading..."
    : slugGunSystemData
    ? `Slug Gun being used = ${slugGunSystemData.machineName}`
    : "No slugGun assigned";

  const durationBagShot = slugGunSystemData?.durationBagShot || "10"; // Default to 10 if not available

  return (
    <>
      <PreventBackNavigation />
      <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
        {isSubmitted ? (
          <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <ResultCard
                  bagWeight={formik.values.bagWeight}
                  durationBagShot={slugGunSystemData?.durationBagShot}
                  docId={docId}
                  showIssueFix={true}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
              {isError && (
                <ErrorAlert
                  errorMessage={errorMessage}
                  errorDetails={errorDetails}
                />
              )}
              <MainCard title={gunNumber}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                      {durationBagShot} Second Ratio Shot:
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={4}
                        sx={{ pt: { xs: 2, sm: "0 !important" } }}
                      >
                        <InputLabel
                          sx={{
                            textAlign: { xs: "left", sm: "right" },
                            fontWeight: 900,
                          }}
                        >
                          ISO Weight :
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={6}>
                        <TextField
                          fullWidth
                          id="ISOWeight"
                          name="ISOWeight"
                          placeholder="grams"
                          type="tel"
                          value={formik.values.ISOWeight}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.ISOWeight &&
                            Boolean(formik.errors.ISOWeight)
                          }
                          helperText={
                            formik.touched.ISOWeight && formik.errors.ISOWeight
                          }
                        />
                        <FormHelperText sx={{ mb: 2 }}>
                          Please enter the weight in grams
                        </FormHelperText>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={4}
                        sx={{ pt: { xs: 2, sm: "0 !important" } }}
                      >
                        <InputLabel
                          sx={{
                            textAlign: { xs: "left", sm: "right" },
                            fontWeight: 900,
                          }}
                        >
                          Poly Weight :
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={6}>
                        <TextField
                          fullWidth
                          id="PolyWeight"
                          name="PolyWeight"
                          placeholder="grams"
                          type="tel"
                          value={formik.values.PolyWeight}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.PolyWeight &&
                            Boolean(formik.errors.PolyWeight)
                          }
                          helperText={
                            formik.touched.PolyWeight &&
                            formik.errors.PolyWeight
                          }
                        />
                        <FormHelperText sx={{ mb: 2 }}>
                          Please enter the weight in grams
                        </FormHelperText>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={4}
                        sx={{ pt: { xs: 2, sm: "0 !important" } }}
                      >
                        <InputLabel
                          sx={{
                            textAlign: { xs: "left", sm: "right" },
                            fontWeight: 900,
                          }}
                        >
                          Bag Weight :
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={6}>
                        <TextField
                          fullWidth
                          id="bagWeight"
                          name="bagWeight"
                          placeholder="grams"
                          type="tel"
                          value={formik.values.bagWeight}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.bagWeight &&
                            Boolean(formik.errors.bagWeight)
                          }
                          helperText={
                            formik.touched.bagWeight && formik.errors.bagWeight
                          }
                        />
                        <FormHelperText sx={{ mb: 3 }}>
                          Please enter the weight in grams
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Visual Check:
                      </Typography>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={4}
                        sx={{ mt: 2, pt: { xs: 2, sm: "0 !important" } }}
                      >
                        <InputLabel
                          sx={{
                            textAlign: { xs: "left", sm: "right" },
                            fontWeight: 900,
                          }}
                        >
                          Does it pass the visual check :
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={6} sx={{ mt: 2 }}>
                        <Grid container spacing={{ xs: 0.5, sm: 2 }}>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              pt: { xs: 2, sm: "0 !important", mt: 4 },
                            }}
                          >
                            <FormControl
                              error={
                                formik.touched.visualCheck &&
                                Boolean(formik.errors.visualCheck)
                              }
                            >
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="visualCheck"
                                value={formik.values.visualCheck}
                                onChange={formik.handleChange}
                              >
                                <FormControlLabel
                                  value="yes"
                                  control={<Radio />}
                                  label={
                                    <Typography sx={{ ml: 1 }}>Yes</Typography>
                                  }
                                  sx={{ ml: 1, mr: 4 }}
                                />
                                <FormControlLabel
                                  value="no"
                                  control={<Radio />}
                                  label={
                                    <Typography sx={{ ml: 1 }}>No</Typography>
                                  }
                                  sx={{ mr: 2 }}
                                />
                              </RadioGroup>
                              {formik.touched.visualCheck &&
                                formik.errors.visualCheck && (
                                  <Box sx={{ width: "100%", mt: 2 }}>
                                    <Alert
                                      severity="error"
                                      sx={{ justifyContent: "center" }}
                                    >
                                      <AlertTitle
                                        sx={{
                                          fontWeight: 900,
                                          fontSize: "1.25rem",
                                          color: "error",
                                        }}
                                      >
                                        Error
                                      </AlertTitle>
                                      {formik.errors.visualCheck}
                                    </Alert>
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                      <AnimateButton>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </AnimateButton>
                    </Stack>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </form>
        )}
        {/* Close of isSubmitted */}
      </Grid>
    </>
  );
};

export default SecondFormComponent;
